/* Organization */
export default [
  {
    path: '/me/organizations/create',
    components: {
      DashboardLayout: () => import("@/components/Organization/OrganizationCreate.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'OrganizationCreate',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/organizations/:organizationKey',
    alias: '/me/organizations/:organizationKey/projects',
    components: {
      DashboardLayout: () => import("@/components/Organization/OrganizationPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Organization',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/organizations',
    components: {
      DashboardLayout: () => import("@/components/Organization/OrganizationList.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Organizations',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/organizations/:organizationKey/projects/:projectId',
    components: {
      DashboardLayout: () => import("@/components/Project/ProjectPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'OrganizationProject',
    meta: {
      requiresAuth: true
    }
  },
]




