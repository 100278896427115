import axios from 'axios'

import store from '../../../store'

export let api = null;

function createInstance(){
	api = axios.create({
		baseURL: store.state.config.baseURL,
		crossDomain: true,
		withCredentials: true,
		credentials: "include",
		responseType: 'json', 
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': '*',
			'Access-Control-Allow-Credentials': 'true',
			"Cache-Control": "no-cache",
		}
	});

	console.log("api", api)
}


export async function setProfilePortfolio(params){
	createInstance();
	console.log("API - setProfilePortfolio", params)
	api.post('/api/v1/users/profile/setPortfolioField', {
		params: params
  }, {headers: {'Authorization': `Bearer ${store.state.user.token}`}})
  .then(response => {
    console.log('setProfilePortfolio response', response)
    /*alert('setProfilePortfolio succesful')*/
  })
  .catch(error => {
    console.error("setProfilePortfolio error", error)
  })
}

export async function sendContactUsRequest(params){
	createInstance();
	console.log("API - sendContactUsRequest", params)
	api.post('/api/public/sendContactUsRequestField', {
		params: params
  })
  .then(response => {
    console.log('sendContactUsRequest response', response)
    /*alert('sendContactUsRequest succesful')*/
  })
  .catch(error => {
    console.error("sendContactUsRequest error", error)
  })
}

export async function sendFeatureRequest(params){
	createInstance();
	console.log("API - sendFeatureRequest", params)
	api.post('/api/public/sendFeatureRequest', {
		params: params
  })
  .then(response => {
    console.log('sendFeatureRequest response', response)
    /*alert('sendFeatureRequest succesful')*/
  })
  .catch(error => {
    console.error("sendFeatureRequest error", error)
  })
}

