//store/modules/mdoel.js

import axios from 'axios';

let controllerRoute = 'hotspots'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  /*responseType: 'json', */
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  hotspots: null
};

const getters = {
  StateHotspots: state => state.hotspots
};

const actions = {
   async StoreHotspots(nill, hotspots) {
    return new Promise((resolve, reject) => {
      api.post(`${controllerRoute}/store`, hotspots, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
      })
      .then(async (response) => {
        console.log("StoreHotspots response", response)
        resolve(response.data)
      })
      .catch(error => {
        console.error("StoreHotspots error", error)
        reject(error)
      })
    })
  },

  async GetModelHotspots({ commit }, path){
    await api.get(`${controllerRoute}/get/${path.organizationKey}/${path.projectKey}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      console.log("GetModelHotspots", response.data.hotspots)
      commit('setModelHotspots', response.data.hotspots)
    })
    .catch(error => {
      console.error("GetModelHotspots error", error)
    })
  },
  
  
};

const mutations = {
  setModelHotspots(state, hotspots){
    state.hotspots = hotspots
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations
};