/*
  Route: *
  login, registration, profile
*/
import authRoute from './auth'

/* Core */
export default [
  ...authRoute,
  {
    path: '/welcome',
    components: {
      DashboardLayout: () => import("@/components/Features/DashboardPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Dashboard',
    meta: {
        requiresAuth: true
    }
  },

  {
    path: '/home',
    alias: '/',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/HomePage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Home',
    meta: {
        requiresAuth: false
    }
  },

]




