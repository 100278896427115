/* Blog */
export default [
  {
    path: '/me/blogs',
    components: {
      DashboardLayout: () => import("@/components/Features/Blog/Me/BlogList.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Blogs',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/blogs/create',
    components: {
      DashboardLayout: () => import("@/components/Features/Blog/Me/BlogCreate.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'BlogCreate',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/blogs/:blogKey',
    components: {
      DashboardLayout: () => import("@/components/Features/Blog/Me/BlogPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Blog',
    meta: {
      requiresAuth: true
    }
  },
]




