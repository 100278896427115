/* Static */
export default [
  {
    path: '/pricing',
    components: {
      DashboardLayout: () => import("@/components/Pricing/PricingPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Pricing',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/panelpanel',
    components: {
      DashboardLayout: () => import("@/components/Features/Editor/PanelPanel.vue"),
      /*TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),*/
    },
    name: 'PanelPanel',
    meta: {
      requiresAuth: false
    }
  },
    
  
  {
    path: '/contactdpo',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/ContactDPOPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'ContactDPO',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/privacypolicy',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/PrivacyPolicy.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'PrivacyPolicy',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/termsofuse',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/TermsOfUsePage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'TermsOfUsePage',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/termsandconditions',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/TermsAndConditions.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'TermsAndConditions',
    meta: {
      requiresAuth: false
    }
  },


  {
    path: '/refundpolicy',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/RefundPolicyPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'RefundPolicyPage',
    meta: {
      requiresAuth: false
    }
  },
  

  {
    path: '/tcs',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/TCs.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'TCs',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/legal',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/LegalPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'LegalPage',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/about',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/About.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'About',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/features',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/FeaturesPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Features',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/featuresrequest',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/FeaturesRequest.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'FeaturesRequest',
    meta: {
      requiresAuth: false
    }
  },
  

  {
    path: '/roadmap',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/RoadmapPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Roadmap',
    meta: {
      requiresAuth: false
    }
  },
]




