/* Models */
export default [
  {
    path: '/me/models',
    components: {
      DashboardLayout: () => import("@/components/Features/Models/ModelList.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Models',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/models/upload',
    components: {
      DashboardLayout: () => import("@/components/Features/Models/ModelUpload.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'ModelUpload',
    meta: {
      requiresAuth: true
    }
  },



  {
    path: '/me/models/view/:modelKey',
    components: {
      DashboardLayout: () => import("@/components/Features/Editor/ModelViewer.vue"),
      /*TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),*/
    },
    name: 'ModelViewer',
    meta: {
      requiresAuth: false
    }
  },


  

  /*{
    path: '/me/models/view/:modelKey',
    components: {
      DashboardLayout: () => import("@/components/Features/Editor/ModelViewer.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'ModelViewer',
    meta: {
      requiresAuth: true
    }
  },*/
]




