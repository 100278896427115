// eslint-disable-next-line no-unused-vars
export default {

  /***
  ***
  *** Authentication API
  ***
  ***/
  // eslint-disable-next-line no-unused-vars
  SET_LOGGED_IN(state, value) {
    /*state.user.loggedIn = value;*/
  },

  set_user(state, value) {
    state.user.data = value;
  },
  
  // eslint-disable-next-line no-unused-vars
  addModel(state, value) {
    /*state.[value.id] = value;*/
  }

}