
/*
  Routes: Core
  e.g.: login, reg, welcome, profile, notfound
*/

import coreRoute from './modules/core'

/*
  Routes: Features
  e.g.: blog, faq, model, organization, portfolio, project
*/
import featuresRoute from './modules/features'


/*
  Routes: Payments
  e.g.: paddle.com, stripe, revolut
*/
import paymentsRoute from './modules/payments'


// Each route should map to a component.
export const routes = [
  ...coreRoute,
  ...featuresRoute,
  ...paymentsRoute,

  /* Admin */
  {
    path: '/admin/subscribedusers',
    components: {
      DashboardLayout: () => import("@/components/SubscribedUsersPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'SubscribedUsersPage',
    meta: {
        requiresAuth: true,
        roles: ['admin']
    }
  },
 
  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    components: {
      DashboardLayout: () => import("@/components/Core/NotFound.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    meta: {
        requiresAuth: false,
        displayName: "",
    },
  },
];
