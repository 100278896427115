/*createLogger*/
import { createStore } from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'


import coreFaq from './modules/core/faq';
import coreCms from './modules/core/cms';

/*import createPersistedState from "vuex-persistedstate";*/
import auth from './modules/auth';
import blog from './modules/blog';
import organization from './modules/organization';
import project from './modules/project';





import projectEnvironments from './modules/project/environments';



import model from './modules/model';
import hotspots from './modules/hotspots';

import backgrounds from './modules/project/Editor/backgrounds';
import landingScreen from  './modules/project/Editor/landingScreen'
import languages from  './modules/project/Editor/languages'
import projectCore from  './modules/project/projectCore'


import paymentPlans from './modules/payment/plans';
import paymentProviders from './modules/payment/providers';

const state = {
  user: {
    loggedIn: false,
    active: true,
    data: null,
    token: null,
  },
  temp: {
    whatToShow: 'login',
    unreadMessageCnt: 0
  },
  env: process?.env,
  config: {
    baseURL: process?.env?.VUE_APP_API_URL,
  },

  modules: {
    editor: {
      refs: {
        modelViewer: null,
        
      },
      operationMode: {
        localStorage: false,
        sessionStorage: false,
        indexedDb: true,
        remoteDb: true,
      },
      selected: new Map(),
      branding: {
        enabled: true,
        brandingLogoUrl: 'https://aip24x7.ltd/nonsense/modelviewerembed/img/logo-white-trans.e6025f8a.png',
        margin: 16,
        size:{
          width: 60,
          height: 'auto'
        },
        alignment: 'top-left',
        style: {top: 0, left: 0},
      },
      ar: {
        enabled: true,
        logoUrl: '/assets/features/nogapAR.png',
        margin: 32,
        size:{
          width: 72,
          height: 'auto'
        },
        style: {bottom: 0, right: 0},
        alignment: 'bottom-right',
        arPlacement: "floor",
        arScale: 'auto'
      }
    },

    licences: {

    },
    currentPlan: 'plan1',
    enabledByPlan:{
      free: {
        modules: [

        ]
      },

      plan1: {
        modules: [
          'brandingLogo',
          'uploadHDR',
          'setBackgroundColor',
          'setBackgroundEnvironment',
          'setBackgroundImage',
          'arEnabled',
          'arCustomTriggerIcon',
          'landingScreenEnabled',
          'landingScreenUIElementText',
          'landingScreenUIElementDivider',
          'landingScreenUIElementImage',
          'landingScreenUIElementVideo',
          'landingScreenUIElementVimeo',
          'landingScreenUIElementYouTube',
          'landingScreenUIElementButton',
          'variantsEnabled',
          'downloadModelEnabled',
          'animationEnabled',
          'animationManagerEnabled',
          'hotspotsEnabled',
          'hotspotsControlCamera',
          'hotspotsUIElements',
          'hotspotsControlAnimation',
          'hotspotsChangeVariant',
          'hotspotsChangeModel',
          'panelEditorEnabled',
        ],
        limits: {
          variants: {},
          animationManager: {},
          hotspots: {},
          panelEditorItems: {},

        }
      },

      plan2: {
        modules: [
          
        ]
      },

      plan3: {
        modules: [
          
        ]
      }
    }

  }
}

export default createStore({
  state,
  getters,
  actions,
  mutations,
  modules: {
    coreFaq,
    coreCms,
    auth,
    blog,
    organization,
    project,
    
    paymentPlans,
    paymentProviders,

    languages,
    projectCore,

    model,
    hotspots,
    projectEnvironments,
    backgrounds,
    landingScreen    
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? []
    : []
})

/*plugins: [createPersistedState()]*/
/*createLogger()*/