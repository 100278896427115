//store/modules/core/cms.js



import axios from 'axios';

let controllerRoute = ''

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/public',
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  page: null
};

const getters = {
  StateCmsPublicPage: state => state.page
};

const actions = {
  /*async CreateFaq({dispatch}, blog) {
    await api.post(`${controllerRoute}/storeFaq`, blog, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetFaqs')
    })
    .catch(error => {
      console.error("CreateFaq error", error)
    })
  },*/

  async GetCmsPublicPage({ commit }, params){
    await api.get(`${controllerRoute}/getCmsPage/${params.pageKey}`)
    .then(response => {
      commit('setCmsPage', response.data.one)
    })
    .catch(error => {
      console.error("GetCmsPublicPage error", error)
    })
  },
};

const mutations = {
  setCmsPage(state, one){
    state.page = one
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};