//store/modules/payment/providers.js

import axios from 'axios';

let controllerRoute = 'plans'
/*let controllerRoute = 'providers'*/

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  payment: {
    providers: null,
    plansByProvider: null
  }
};

const getters = {
  StatePaymentProviders: state => state.payment.providers,
  StatePaymentProvidersPlans: state => state.payment.plansByProvider
};

const actions = {
  /*async CreateBlog({dispatch}, blog) {
    await api.post(`${controllerRoute}/create`, blog, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetMyBlogs')
    })
    .catch(error => {
      console.error("CreateBlog error", error)
    })
  },*/


  async getPaymentProviders({ commit }){
    await api.get(`${controllerRoute}/listPaymentProviders`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetPaymentProviders', response.data.list)
    })
    .catch(error => {
      console.error("getSubscriptions error", error)
    })
  },

  async getPaymentProvidersPlans({ commit }){
    await api.get(`${controllerRoute}/listPaymentProvidersPlans`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetPaymentProvidersPlans', response.data.list)
    })
    .catch(error => {
      console.error("getPaymentProvidersPlans error", error)
    })
  },
};

const mutations = {
  SetPaymentProviders(state, list){
    state.payment.providers = list
  },

  SetPaymentProvidersPlans(state, list){
    state.payment.plansByProvider = list
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};