/* Projects */
export default [
  {
    path: '/me/projects/:organizationKey/:projectKey',
    components: {
      DashboardLayout: () => import("@/components/Project/ProjectPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Project',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/projects/:organizationKey/:projectKey/editorpanel',
    components: {
      DashboardLayout: () => import("@/components/Features/Editor/PanelPanel.vue")
    },
    name: 'ProjectEditorPanel',
    meta: {
      requiresAuth: true
    }
  }


]




