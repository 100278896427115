//store/modules/project/environment.js

import axios from 'axios';

let controllerRoute = 'projects'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  environments: null,
  /*environment: null*/
};

const getters = {
  StateOrganizationProjectEnvironments: state => state.environments,
  /*StateOrganizationProjectEnvironment: state => state.environment,*/
};

const actions = {

  async CreateEnvironment(nill, environment) {
    return new Promise((resolve, reject) => {
      api.post(`${controllerRoute}/environments/create`, environment, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateEnvironment error", error)
        reject(error)
      })
    })
  },

  async GetOrganizationProjectEnvironments({ commit }, params){
    await api.get(`${controllerRoute}/get/${params.organizationKey}/${params.projectKey}/environments/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setEnvironments', response.data.environments)
    })
    .catch(error => {
      console.error("GetOrganizationProjectEnvironments error", error)
    })
  },

  /*async GetOrganizationProjectEnvironment({ commit }, params){
    await api.get(`${controllerRoute}/get/${params.organizationKey}/${params.projectKey}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setEnvironment', response.data.environment)
    })
    .catch(error => {
      console.error("GetOrganizationProjectEnvironment error", error)
    })
  },*/
};

const mutations = {
  setEnvironments(state, environments){
    state.environments = environments
  },

  /*setEnvironment(state, environment){
    state.environment = environment
  }*/
};

export default {
  state,
  getters,
  actions,
  mutations
};