/* AUTH */
export default [
  {
    path: '/login',
    components: {
      DashboardLayout: () => import("@/components/Core/Login.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
    },
    name: 'Login',
    meta: {
        requiresAuth: false
    }
  },

  {
    path: '/me/profile',
    components: {
      DashboardLayout: () => import("@/components/Profile/ProfilePage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'Profile',
    meta: {
        requiresAuth: true
    }
  },
]




