/* Portfolio */
export default [
  {
    path: '/me/portfolio',
    components: {
      portfolio: () => import("@/components/Portfolio/Me/PortfolioWrapper.vue"),
    },
    name: 'Portfolio',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/me/portfolio/requests',
    components: {
      DashboardLayout: () => import("@/components/Portfolio/Me/PortfolioMyRequests.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'PortfolioRequests',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/portfolio/:userKey',
    components: {
      portfolio: () => import("@/components/Portfolio/UserPortfolio.vue"),
    },
    name: 'PortfolioUser',
    meta: {
      requiresAuth: false
    }
  },
]




