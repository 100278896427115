/*
export const sortedMessages = (state, getters) => {
  const messages = getters.currentMessages
  return messages.slice().sort((a, b) => a.timestamp - b.timestamp)
}
*/

export const user = state => state.user;

export const config = state => state.config;

export const temp = state => state.temp;

export const env = state => state.env;

export const whatToShow = state => state.temp.whatToShow;
