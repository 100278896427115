//store/modules/Editor/landingScreen.js

/*import axios from 'axios';*/

/*let controllerRoute = 'landingScreen'*/

/*let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});*/

const state = {
  languagesById: new Map(),
  availableLanguages: {
    'en' : {
      slug: 'en',
      name: 'english',
      localName: 'english',
      rtl: false,
      flag: '',
      isDeletable: false
    },
    'es' : {
      slug: 'es',
      name: 'spanish',
      localName: 'espanol',
      rtl: false,
      flag: '',
      isDeletable: true
    },
    'hu' : {
      slug: 'hu',
      name: 'hungarian',
      localName: 'magyar',
      rtl: false,
      flag: '',
      isDeletable: true
    },
    'ar' : {
      slug: 'ar',
      name: 'arabic',
      localName: 'arabic',
      rtl: true,
      flag: '',
      isDeletable: true
    },

  },
};

const getters = {
  StateEditorLanguagesById: state => state.languagesById,
};

const actions = {
  async AddLanguage({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params) {
        commit('setLanguagesById', params)
        resolve({error: false})  
      }
      
      reject({error: true})

      /*
      api.post(`${controllerRoute}/backgrounds/create`, background, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateBackground error", error)
        reject(error)
      })*/
    })
  },
  
};

const mutations = {
  setLanguagesById(state, params){
    state.languagesById.set(params.slug, {name: params.name, rtl: params.rtl, slug: params.slug, isDeletable: params.isDeletable})
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};