//store/modules/core/faq.js

import axios from 'axios';

let controllerRoute = 'faq'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/public',
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  faq: {
    product: null,
    payment: null,
  }
};

const getters = {
  StateFaqPayment: state => state.faq.payment,
  StateFaqProduct: state => state.faq.product
};

const actions = {
  /*async CreateFaq({dispatch}, blog) {
    await api.post(`${controllerRoute}/storeFaq`, blog, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetFaqs')
    })
    .catch(error => {
      console.error("CreateFaq error", error)
    })
  },*/

  async GetFaqPayment({ commit }){
    await api.get(`${controllerRoute}/payment`)
    .then(response => {
      commit('setFaqPayment', response.data.list)
    })
    .catch(error => {
      console.error("FaqPayment error", error)
    })
  },

  async GetFaqProduct({ commit }){
    await api.get(`${controllerRoute}/product/`)
    .then(response => {
      commit('setFaqProdcut', response.data.list)
    })
    .catch(error => {
      console.error("GetFaqProduct error", error)
    })
  },
};

const mutations = {
  setFaqPayment(state, list){
    state.faq.payment = list
  },

  setFaqProdcut(state, list){
    state.faq.product = list
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};