<template>
  <!-- <HelloWorld ref="foo" v-if="this.$route.name != 'Portfolio' && this.$route.name != 'Organizations'"/> -->
  <router-view name="portfolio"></router-view>
  <div class="container">
    <router-view name="TopMenuBar"></router-view>
    <router-view name="DashboardLayout"></router-view>
    <router-view name="FooterBar"></router-view>
  </div>
</template>

<script>
/*import HelloWorld from './components/HelloWorld.vue'*/

import axios from 'axios'

import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    /*HelloWorld*/
  },
  
  computed: {
    ...mapGetters({
      user: "user",
      config: "config",
      temp: "temp"
    })
  },
  data: () => {
    return {
      token: null
    }
  },
  methods: {
    async getProfile() {
      this.api.get('/api/v1/users/profile/me', {headers: {'Authorization': `Bearer ${this.token}`}})
      .then(async response => {
        // dispatch with a payload
        /*console.log("getProfile", response.data.user)*/
        await this.$store.dispatch('setUser', response.data.user)
        this.getUnreadMessageCnt()
        /*console.log("this.$store.state", this.$store.state)*/
      })
      .catch(error => {
        console.error("getProfile error", error)
      })
      
    },

    async getUnreadMessageCnt() {
      this.api.get('/api/v1/users/profile/unreadMessageCnt', {headers: {'Authorization': `Bearer ${this.user.token}`}})
      .then(response => {
        this.temp.unreadMessageCnt = response.data.cnt
      })
      .catch(error => {
        console.error("getUnreadMessageCnt error", error)
      })
    },

    /*async checkProfile() {
      await this.api.get('/api/v1/tokenuser', {headers: {'Authorization': `Bearer ${this.token}`}})
      .then(() => {
        console.log(resp);
      })
      .catch(error => {
        console.error("checkProfile error", error)
      });

      this.getProfile();
      
    },*/
  },

  beforeMount() {
    /*console.log("APPVUE beforeMount")*/
    this.api = axios.create({
      baseURL: this.config.baseURL,
      crossDomain: true,
      withCredentials: true,
      credentials: "include",
      responseType: 'json', 
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        "Cache-Control": "no-cache",
      }
    })

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('jwt')) {
      console.log("url parmas")
      this.token = urlParams.get('jwt');
      this.$cookies.set("user", {token: this.token}, process?.env?.VUE_APP_COOKIE_USER_EXPIRE);
      this.$store.state.user.token = this.token
      window.history.replaceState(null, null, window.location.pathname);
    }

    if (this.$cookies.get('user') != 'null' && this.$cookies.get('user') != null) {
      let u = this.$cookies.get('user');
      this.token = u.token
      this.$store.state.user.token = this.token
    }else{
      console.log('not get it')
    }

    /*console.log(urlParams.get('jwt')); // "MyParam"*/
    /*token.replace('#_=_', '');*/
    if (this.token) {
      /*this.checkProfile()*/
      this.getProfile()
    }
  },


  mounted(){    
    
  }
}
</script>

<style>

#app {
  margin-top: 75px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cursor-alias {cursor: alias;}
.cursor-all-scroll {cursor: all-scroll;}
.cursor-auto {cursor: auto;}
.cursor-cell {cursor: cell;}
.cursor-context-menu {cursor: context-menu;}
.cursor-col-resize {cursor: col-resize;}
.cursor-copy {cursor: copy;}
.cursor-crosshair {cursor: crosshair;}
.cursor-default {cursor: default;}
.cursor-e-resize {cursor: e-resize;}
.cursor-ew-resize {cursor: ew-resize;}
.cursor-grab {cursor: -webkit-grab; cursor: grab;}
.cursor-grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.cursor-help {cursor: help;}
.cursor-move {cursor: move;}
.cursor-n-resize {cursor: n-resize;}
.cursor-ne-resize {cursor: ne-resize;}
.cursor-nesw-resize {cursor: nesw-resize;}
.cursor-ns-resize {cursor: ns-resize;}
.cursor-nw-resize {cursor: nw-resize;}
.cursor-nwse-resize {cursor: nwse-resize;}
.cursor-no-drop {cursor: no-drop;}
.cursor-none {cursor: none;}
.cursor-not-allowed {cursor: not-allowed;}
.cursor-pointer {cursor: pointer;}
.cursor-progress {cursor: progress;}
.cursor-row-resize {cursor: row-resize;}
.cursor-s-resize {cursor: s-resize;}
.cursor-se-resize {cursor: se-resize;}
.cursor-sw-resize {cursor: sw-resize;}
.cursor-text {cursor: text;}
.cursor-w-resize {cursor: w-resize;}
.cursor-wait {cursor: wait;}
.cursor-zoom-in {cursor: zoom-in;}
.cursor-zoom-out {cursor: zoom-out;}

</style>
