//store/modules/projectCore.js

import axios from 'axios';

let controllerRoute = 'projects'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  selected: new Map().set('language', { slug: 'en' })
};

const getters = {
  /*StateEditorLanguagesById: state => state.languagesById,*/
};

const actions = {

  /*projectCore
  onChangeLanguage(e){
    this.selected.set('language', { slug: e.target.value })
    this.uiElementsById = this.uiElementsByLanguage.get(this.selected.get('language').slug);

    
    if (this.uiElementsById == null) {
      this.uiElementsByLanguage.set(this.selected.get('language').slug, new Map());
      this.uiElementsById = new Map();
    }

    this.landingScreenUiElementsById = this.uiElementsByLanguage.get(this.selected.get('language').slug);
    if (this.landingScreenUiElementsById == null) {
      this.landingScreenUiElementsById = new Map();
    }
  },*/
  async onChangeLanguage({ commit }, language) {
    return new Promise((resolve, reject) => {
      console.log("dispatch onChangeLanguage", language)
      if (language) {
        commit("setSelectedLanguage", language)
        resolve({error: false})  
      }
      
      reject({error: true})

      /*
      api.post(`${controllerRoute}/backgrounds/create`, background, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateBackground error", error)
        reject(error)
      })*/
    })
  },

  async UpdateProjectProperties(nill, object) {
    return new Promise((resolve, reject) => {
      api.post(`${controllerRoute}/updateProjectProperties`, object, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("UpdateProjectProperties error", error)
        reject(error)
      })
    })
  },
  
};

const mutations = {
  setSelectedLanguage(state, language){
    console.log("setSelectedLanguage", language)
    state.selected.set('language', { slug: language })
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};