//store/modules/organization.js

import axios from 'axios';

let controllerRoute = 'organization'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  my: {
    organizations: null,
  }
};

const getters = {
  StateOrganizations: state => state.my.organizations,
};

const actions = {
  async CreateOrganization(nill , organization) {
    return new Promise((resolve, reject) => {
      console.log("CreateOrganization", organization)

      api.post(`${controllerRoute}/create`, organization, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateOrganization error", error)
        reject(error)
      })
    })
  },

  async GetMyOrganizations({ commit }){
    console.log("GetMyOrganizations")
    await api.get(`${controllerRoute}/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setOrganizations', response.data.organizations)
    })
    .catch(error => {
      console.error("GetMyOrganizations error", error)
    })
  },
};

const mutations = {
  setOrganizations(state, organizations){
    state.my.organizations = organizations
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};