import * as api from '../api'

export const setUser = ({ commit }, user) => {
  commit("set_user", user);
}

export const setProfilePortfolio = async (nill, param) => {
  return new Promise((resolve) => {
    console.log("setProfilePortfolio", param)
    // eslint-disable-next-line no-unused-vars
    api.portfolio.setProfilePortfolio(param, session => {
      resolve(null)
    })
  })   
}

export const sendContactUsRequest = async (nill, param) => {
  return new Promise((resolve) => {
    console.log("sendContactUsRequest", param)
    // eslint-disable-next-line no-unused-vars
    api.portfolio.sendContactUsRequest(param, session => {
      resolve(null)
    })
  })   
}

export const sendFeatureRequest = async (nill, param) => {
  return new Promise((resolve) => {
    console.log("sendFeatureRequest", param)
    // eslint-disable-next-line no-unused-vars
    api.portfolio.sendFeatureRequest(param, session => {
      resolve(null)
    })
  })   
}


