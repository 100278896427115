/*
  Route: *
  
*/
import paddleRoute from './paddle'

/* Auth */
export default [
  ...paddleRoute,

  {
    path: '/admin/payments/subscriptionpackage/:planKey',
    components: {
      DashboardLayout: () => import("@/components/admin/payments/SubscriptionPackageLocal.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
    },
    name: 'SubscriptionPackageLocal',
    meta: {
        requiresAuth: true
    }
  },
]




