//store/modules/mdoel.js

import axios from 'axios';

let controllerRoute = 'models'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  /*responseType: 'json', */
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  my: {
    models: null,
    model: null
  },
  organization: {
    project: {
      models: null,
      model: null
    }
  }
};

const getters = {
  StateModels: state => state.my.models,
  StateModel: state => state.my.model,
  StateOrganizationProjectModels: state => state.organization.project.models,
  
};

const actions = {
 /* addModel(e) {
      e.preventDefault();

      const formElem = document.getElementById("form")

      const formData = new FormData(formElem)
      console.log("formData", formData)
      console.log("formData files", formData.get('files'))

      fetch(this.config.baseURL+"/api/v1/uploadModels", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${this.user.token}`
        }
      })
      .then((res) => {
        console.log('fileupload res', res);
        this.$router.push({ name: 'Models', params: {} })
      })
      .catch((err) => ("Error occured fileupload", err));
    },

*/

  async CreateModel(nill, model) {
    return new Promise((resolve, reject) => {
      api.post(`${controllerRoute}/create`, model, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateModel error", error)
        reject(error)
      })
    })
  },

  async GetMyModels({ commit }){
    await api.get(`${controllerRoute}/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setModels', response.data.models)
    })
    .catch(error => {
      console.error("GetMyModels error", error)
    })
  },

  async GetMyModel({ commit }, key){
    await api.get(`${controllerRoute}/get/${key}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setModel', response.data.model)
    })
    .catch(error => {
      console.error("GetMyModel error", error)
    })
  },

  async GetOrganizationProjectModels({ commit }, params){
    await api.get(`${controllerRoute}/getOrganizationProjectModels/${params.organizationKey}/${params.projectKey}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setOrganizationProjectModels', response.data.models)
    })
    .catch(error => {
      console.error("GetOrganizationProjectModels error", error)
    })
  },

  async GetOrganizationProjectModel({ commit }, params){
    await api.get(`${controllerRoute}/getOrganizationProjectModel/${params.organizationKey}/${params.projectKey}/properties/model`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setOrganizationProjectModel', response.data)
    })
    .catch(error => {
      console.error("GetOrganizationProjectModel error", error)
    })
  },

  
  
};

const mutations = {
  setModels(state, models){
    state.my.models = models
  },
  
  setOrganizationProjectModels(state, models){
    state.organization.project.models = models
  },

  setOrganizationProjectModel(state, model){
    state.organization.project.model = model
  },

  setModel(state, model){
    state.my.model = model
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};