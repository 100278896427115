//store/modules/Editor/landingScreen.js

/*import axios from 'axios';*/

/*let controllerRoute = 'landingScreen'*/

/*let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});*/

const state = {
  landingScreenModal: {
    isEnabled: false,
    showLanguageSelector: false,
    isShown: false,
    size: 'modal-lg'
  },

  landingScreenUiElementsByLanguage: new Map()
};

const getters = {
  StateEditorLandingScreenModal: state => state.landingScreenModal,
  StateEditorLandingScreenUiElementsByLanguage: state => state.landingScreenUiElementsByLanguage,
};

const actions = {
  async AddLandingScreenUiElement({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params) {

        console.log("AddLandingScreenUiElement state", this.state.projectCore.selected.get('language').slug)
        commit('setLandingScreenUiElementsByLanguage', params)
        resolve({error: false})  
      }
      
      reject({error: true})


      /*
      api.post(`${controllerRoute}/backgrounds/create`, background, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateBackground error", error)
        reject(error)
      })*/
    })
  },


  
};

const mutations = {
  setLandingScreenModal(state, landingScreenModal){
    state.landingScreenModal = landingScreenModal
  },

  setLandingScreenUiElementsByLanguage(state, params){
    const selectedLanguage = this.state.projectCore.selected.get('language').slug
    let act = state.landingScreenUiElementsByLanguage.get(selectedLanguage)
    if (act) {
      act.set(Date.now(), params)
      state.landingScreenUiElementsByLanguage.set(selectedLanguage, act)
    }else{
      act = state.landingScreenUiElementsByLanguage.set(selectedLanguage, new Map().set(Date.now(), params))
    }

  }
};

export default {
  state,
  getters,
  actions,
  mutations
};