//store/modules/blogs.js

import axios from 'axios';

let controllerRoute = 'blogs'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  my: {
    blog: null,
    blogs: null,
  }
};

const getters = {
  StateBlogs: state => state.my.blogs,
  StateBlog: state => state.my.blog,
};

const actions = {
  async CreateBlog({dispatch}, blog) {
    await api.post(`${controllerRoute}/create`, blog, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetMyBlogs')
    })
    .catch(error => {
      console.error("CreateBlog error", error)
    })
  },

  async GetMyBlogs({ commit }){
    await api.get(`${controllerRoute}/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setBlogs', response.data.blogs)
    })
    .catch(error => {
      console.error("GetMyBlogs error", error)
    })
  },

  async GetMyBlog({ commit }, key){
    await api.get(`${controllerRoute}/get/${key}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setBlog', response.data.blog)
    })
    .catch(error => {
      console.error("GetMyBlog error", error)
    })
  },
};

const mutations = {
  setBlogs(state, blogs){
    state.my.blogs = blogs
  },

  setBlog(state, blog){
    state.my.blog = blog
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};