/* FAQ */
export default [
  {
    path: '/faq',
    components: {
      DashboardLayout: () => import("@/components/Cms/Static/FaqPage.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
      FooterBar: () => import("@/components/Layout/Footer/FooterBar.vue"),
    },
    name: 'FAQ',
    meta: {
      requiresAuth: false
    }
  },
]




