import { createApp } from 'vue'
import App from './App.vue'
import VueClickAway from "vue3-click-away";

import { createWebHashHistory, createRouter } from "vue-router";
import VueCookies from 'vue-cookies'
import {routes} from './router/index.js'

import store from './store'

import "./bootstrap_variable_override.scss"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  base: process?.env?.ROUTER_BASE, //add base parameter = ROUTER_BASE from env file ...
  routes, // short for `routes: routes`
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})


/*
4.Handling Expired Token (Forbidden Requests)

Our API is set to expire tokens after 30 minutes, 
now if we try accessing the posts page after 30 minutes, 
we get a 401 error, which means we have to log in again, 
so we will set an interceptor that reads if we get a 401 error then it redirects us back to the login page.

import axios from 'axios';

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
  
        originalRequest._retry = true;
        store.dispatch('LogOut')
        return router.push('/login')
    }
  }
})
*/






const vueApp = createApp(App);

vueApp.use(router).use(store).use(VueCookies).mount('#app')
vueApp.use(VueClickAway) // Makes 'v-click-away' directive usable in every component

const auth = {
  isLoggedIn(){
    /*console.log("isLoggedIn()")*/
    let ret = !(!store.state.user.data && !(VueCookies.get('user') != 'null' && VueCookies.get('user') != null))
    /*console.log("ret", ret)*/
    return ret
  }
}

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  /*console.log("from", from)*/
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    /*console.log("to.meta.requiresAuth && !auth.isLoggedIn()")*/
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }
})

