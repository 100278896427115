/* Paddle.com */
export default [
  {
    path: '/admin/payments/paddle/subscriptionpackages',
    components: {
      DashboardLayout: () => import("@/components/admin/payments/paddle/SubscriptionPackages.vue"),
      TopMenuBar: () => import("@/components/Layout/Header/TopMenu.vue"),
    },
    name: 'PaddlecomSubscriptionPackages',
    meta: {
        requiresAuth: true
    }
  }
]




