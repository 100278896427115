/*
  Route: /me/blogs/*
*/
import blogRoute from './blog'

/*
  Routes: /portfolio, /me/portfolio/*
*/
import portfolioRoute from './portfolio'

/*
  Routes: /me/organizations/*
*/
import organizationRoute from './organization'

/*
  Routes: /me/projects/*
*/
import projectRoute from './project'

/*
  Routes: /me/models/*
*/
import modelRoute from './model'

/*
  Routes: /me/faq/*
*/
import faqRoute from './faq'

import staticRoute from './static'

/* Static */
export default [
  ...blogRoute,
  ...organizationRoute,
  ...portfolioRoute,
  ...projectRoute,
  ...modelRoute,
  ...staticRoute,
  ...faqRoute,
]




