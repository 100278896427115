//store/modules/payment/plans.js

import axios from 'axios';

let controllerRoute = 'plans'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  subscriptions: null,
  subscription: null,
  localPlans: null,
  localPlan: null
};

const getters = {
  StateSubscriptions: state => state.subscriptions,
  StateSubscription: state => state.subscription,
  StateLocalPlans: state => state.localPlans,
  StateLocalPlan: state => state.localPlan,
  
};

const actions = {
  /*async CreateBlog({dispatch}, blog) {
    await api.post(`${controllerRoute}/create`, blog, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetMyBlogs')
    })
    .catch(error => {
      console.error("CreateBlog error", error)
    })
  },*/

  async syncPaddlePlans({ dispatch }){
    console.log("async syncPaddlePlans")
    await api.get(`${controllerRoute}/syncPaddlePlans`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('getLocalPlans')
    })
    .catch(error => {
      console.error("getSubscriptions error", error)
    })
  },

  async getSubscriptions({ commit }){
    await api.get(`${controllerRoute}/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetSubscriptions', response.data.list)
    })
    .catch(error => {
      console.error("getSubscriptions error", error)
    })
  },

  async getLocalPlans({ commit }){
    await api.get(`${controllerRoute}/local`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetLocalPlans', response.data.list)
    })
    .catch(error => {
      console.error("getLocalPlans error", error)
    })
  },

  async getSubscription({ commit }, key){
    await api.get(`${controllerRoute}/getLocal/${key}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetSubscription', response.data.one)
    })
    .catch(error => {
      console.error("getSubscription error", error)
    })
  },

  async getSubscriptionLocal({ commit }, key){
    await api.get(`${controllerRoute}/getLocal/${key}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('SetSubscriptionLocal', response.data.one)
    })
    .catch(error => {
      console.error("getSubscriptionLocal error", error)
    })
  },
};

const mutations = {
  SetSubscriptions(state, list){
    state.subscriptions = list
  },

  SetSubscription(state, one){
    state.subscription = one
  },

  SetSubscriptionLocal(state, one){
    state.localPlan = one
  },

  SetLocalPlans(state, list){
    state.localPlans = list
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};