//store/modules/project/backgrounds.js

import axios from 'axios';

let controllerRoute = 'projects'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  backgrounds: null,
  background: {
    enabled: false,

    type: 'solidColor',
    solidColor: 'red',

    byId: new Map(),
    image: {
      imageUrl: null,
      brightness: 1,
      bluriness: 1,
    },

    environment: {
      imageUrl: null,
      brightness: 1,
      bluriness: 1,
    }
  },
};

const getters = {
  StateOrganizationProjectBackgrounds: state => state.backgrounds,
  /*StateOrganizationProjectBackground: state => state.background,*/
};

const actions = {

  async CreateBackground(nill, background) {
    return new Promise((resolve, reject) => {
      api.post(`${controllerRoute}/backgrounds/create`, background, {headers: {
        'Authorization': `Bearer ${this.state.user.token}`},
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .then(async (response) => {
        resolve(response.data)
      })
      .catch(error => {
        console.error("CreateBackground error", error)
        reject(error)
      })
    })
  },

  async GetOrganizationProjectBackgrounds({ commit }, params){
    await api.get(`${controllerRoute}/get/${params.organizationKey}/${params.projectKey}/backgrounds/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setBackgrounds', response.data.backgrounds)
    })
    .catch(error => {
      console.error("GetOrganizationProjectBackgrounds error", error)
    })
  },

  /*async GetOrganizationProjectBackground({ commit }, params){
    await api.get(`${controllerRoute}/get/${params.organizationKey}/${params.projectKey}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setBackground', response.data.background)
    })
    .catch(error => {
      console.error("GetOrganizationProjectBackground error", error)
    })
  },*/
};

const mutations = {
  setBackgrounds(state, backgrounds){
    state.backgrounds = backgrounds
  },

  setBackground(state, background){
    state.background = background
  },

  /*setBackground(state, background){
    state.background = background
  }*/
};

export default {
  state,
  getters,
  actions,
  mutations
};