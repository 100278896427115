//store/modules/prototype.js

import axios from 'axios';

let controllerRoute = 'projects'

let api = axios.create({
  baseURL: process?.env?.VUE_APP_API_URL+'/api/'+process?.env?.VUE_APP_API_VERSION,
  crossDomain: true,
  withCredentials: true,
  credentials: "include",
  responseType: 'json', 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    "Cache-Control": "no-cache",
  }
});

const state = {
  projects: null,
  project: null,
  transactions: null
};

const getters = {
  StateProject: state => state.project,
  StateProjects: state => state.projects,
  StateTransactions: state => state.transactions,
};

const actions = {
  /*async CreateProject({dispatch}, project) {
    await api.post(`${controllerRoute}/create`, project, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
      await dispatch('GetMyProjects')
    })
    .catch(error => {
      console.error("CreateProject error", error)
    })
  },*/

  async GetMyProject({ commit }, params){
    await api.get(`${controllerRoute}/get/${params.organizationKey}/${params.projectKey}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setProject', response.data.project)
    })
    .catch(error => {
      console.error("GetMyProject error", error)
    })
  },
  
  async GetMyProjects({ commit }){
    await api.get(`${controllerRoute}/list`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      commit('setProjects', response.data.projects)
    })
    .catch(error => {
      console.error("GetMyProjects error", error)
    })
  },

  async GetOrganizationProjectTransactions({ commit }, params){
    console.log("GetOrganizationProjectTransactions params", params)
    await api.get(`subscriptions/transactions/checkout/${params.checkout.id}`, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(response => {
      console.log("GetOrganizationProjectTransactions response.data", response.data)
      commit('setTransactions', response.data.transactions)
    })
    .catch(error => {
      console.error("GetOrganizationProjectTransactions error", error)
    })
  },

  async setProjectAssemblerField( nill, params){
    console.log("setProjectAssemblerField params", params)
    await api.post(`${controllerRoute}/setProjectAssemblerField`, {
      update: {
        organizationKey: params.update.object.organizationKey,
        projectKey: params.update.object.projectKey,
        key: params.update.key,
        value: params.update.value
      }
    }, {headers: {'Authorization': `Bearer ${this.state.user.token}`}})
    .then(async () => {
    })
    .catch(error => {
      console.error("setProjectAssemblerField error", error)
    })
  }
};

const mutations = {
  setTransactions(state, transactions){
    state.transactions = transactions
  },

  setProjects(state, projects){
    state.projects = projects
  },

  setProject(state, project){
    state.project = project
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};